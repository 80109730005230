/* I n f o */
/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
A simple library that aims to make Firebase database usage easier to use
and faster to implement into new projects.

Things that need to be done when adding a new firebase Database:
	Link the firebase script //3.6.9
	Update configuration below
	Update Firebase permission rules (database)
	Update Firebase permission rules (storage)
	enable 'public links' from console.cloud.google.com - note this will make ALL uploaded file URLs 'guessable'
	-->		gsutil defacl ch -u allUsers:R gs://BUCKET-LINK-HERE
	Remember to add DOMPurify to sterilize (HTML) string being passed back and fourth from Firebase

This library adds the following functions: (see specific notes for usage)
	loadFromFirebasePath(path)
	saveToFirebasePath(path, object)
	saveToFirebaseUser(object)
	saveImageToFirebaseStorage(path, file, filename)
	appendHTML(element, html)

To run script mentioned higher up on the page that requires firebase, use
	onFirebaseLoad() { do stuff here }
	onfirebaseLoadLoggedIn() { do stuff here }



To run the functions in asyncronous order, simply nest these functions
(where .then() is applicable) and the promises should work as intended
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/

/* G l o b a l   V a r i a b l e s */
var config = {
  apiKey: process.env.FIRST_DATABASE_APIKEY,
  authDomain: process.env.FIRST_DATABASE_AUTHDOMAIN,
  databaseURL: process.env.FIRST_DATABASE_DATABASEURL,
  projectId: process.env.FIRST_DATABASE_PROJECTID,
  storageBucket: process.env.FIRST_DATABASE_STORAGEBUCKET,
  messagingSenderId: process.env.FIRST_DATABASE_MESSAGINGSENDERID,
};
firebase.initializeApp(config);

var storagePathURL = 'https://storage.googleapis.com/' + config.storageBucket + '/';
var storage = firebase.storage();
var storageRef = storage.ref();
window.user;

// onLoad to do something higher up on the page *once*.
var firebaseInitialisedForTheFirstTime = false;
firebase.auth().onAuthStateChanged(function(e) {
  //if (firebaseInitialisedForTheFirstTime == false) {
  if (e) {
    // set the user if someone is already logged in
    user = firebase.auth().currentUser;
    //unhideMembersNavSections();
    // If onLoad while logged in - useful for setting a username to show on all pages
  } else {
    user = undefined;
  }
  firebaseInitialisedForTheFirstTime = true;
  if (typeof onFirebaseLoad == 'function') {
    onFirebaseLoad();
  }
  //} else {
  //	console.warn('onFirebaseLoad is not defined!');
  //}
});

/* F u n c t i o n s */
/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
loadFromFirebasePath(path)

Info:
returns a *PROMISE OBJECT* containing the object

Example usage:
loadFromFirebasePath('/franchisee/' + user.uid).then(function(obj) {
	appendHTML('#testContainer', '<p>' + obj.collectionCodeID + '</p>');
	appendHTML('#testContainer', '<p>' + obj.name + '</p>');
});
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.loadFromFirebasePath = loadFromFirebasePath;
function loadFromFirebasePath(path) {
  try {
    return firebase
      .database()
      .ref(path)
      .once('value')
      .then(
        function(snapshot) {
          return snapshot.val();
        },
        function(error) {
          throw 'There was an error reading data';
        }
      );
  } catch (err) {
    console.error(err);
    console.trace('^');
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
saveToFirebasePath(path, object)

Info:
saves new items into the specified path

Example usage:
saveToFirebasePath('/franchisee/' + user.uid, {
	'thing': 'this is new',
	'other thing': 'something else'
});
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.saveToFirebasePath = saveToFirebasePath;
function saveToFirebasePath(path, object) {
  try {
    return firebase
      .database()
      .ref(path)
      .update(object)
      .then(
        function() {
          //console.log('Successfully wrote to ' + path)
        },
        function(err) {
          throw err;
        }
      );
  } catch (err) {
    console.error(err);
    console.trace('^');
  }
}

window.saveToFirebasePathSET = saveToFirebasePathSET;
function saveToFirebasePathSET(path, object) {
  try {
    return firebase
      .database()
      .ref(path)
      .set(object)
      .then(
        function() {
          //console.log('Successfully wrote to ' + path)
        },
        function(err) {
          throw err;
        }
      );
  } catch (err) {
    console.error(err);
    console.trace('^');
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
saveToFirebaseUser(object)

Info:
saves new user information - displayName photoURL email password

Example usage:
saveToFirebaseUser({
	'displayName': 'this is new'
});
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.saveToFirebaseUser = saveToFirebaseUser;
function saveToFirebaseUser(object) {
  try {
    return user.updateProfile(object).then(
      function() {
        //console.log('Successfully updated user info ')
      },
      function(err) {
        throw err;
      }
    );
  } catch (err) {
    console.error(err);
    console.trace('^');
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
saveImageToFirebaseStorage(path, file, filename)

Info:
Saves an image to Firebase storage and returns the url

Example usage:
var imageFile = document.getElementById("franchiseImage").files[0];
saveImageToFirebaseStorage('editCollectionCodeID', imageFile, 'exampleFileName').then(function(url) {
    console.log(url.a.downloadURLs[0]);
});
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.saveImageToFirebaseStorage = saveImageToFirebaseStorage;
function saveImageToFirebaseStorage(path, file, filename) {
  try {
    if (!(file instanceof Blob)) {
      throw 'You have not selected a blob!';
    }
    //if (typeof file.name !== 'string'){throw 'You have not selected a blob/file!';}
    //if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)){throw 'This is not an image!';}
    var fileExtension = 'png';
    //if (file) {
    //	fileExtension = file.name.split('.').pop();
    //} else {
    //	fileExtension = 'png'
    //}
    // Create the file metadata
    var metadata = {
      contentType: 'image/' + fileExtension,
    };
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(path + '/' + filename + '.' + fileExtension).put(file, metadata);
    return uploadTask;
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            //console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            //console.log('Upload is running');
            break;
        }
      },
      function(error) {
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        var downloadURL = uploadTask.snapshot.downloadURL;
      }
    );
  } catch (err) {
    console.error(err);
    console.trace('^');
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
appendHTML('#element', 'html')

Info: Appends html to an element via id, or appends html to all
elements that have Specified class.

Example usage:
appendHTML('#singleArea', '<p>' + obj.name + '</p>');
appendHTML('.multipleAreas', '<p>' + obj.name + '</p>');
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
window.appendHTML = appendHTML;
function appendHTML(a, b) {
  try {
    if (a == undefined || b == undefined) {
      throw 'Something has not been defined! -> appendHTML(elementToAppendTo, HTMLToAppend);';
    } else {
      if (a.startsWith('#')) {
        var elementToAppendTo = document.querySelector(a);
        var newInnerElement = document.createElement('div');
        // Create an element and append it
        newInnerElement.innerHTML = b;
        elementToAppendTo.appendChild(newInnerElement);
        // Remove the parent div
        var fragment = document.createDocumentFragment();
        while (newInnerElement.firstChild) {
          fragment.appendChild(newInnerElement.firstChild);
        }
        newInnerElement.parentNode.replaceChild(fragment, newInnerElement);
      } else {
        if (a.startsWith('.')) {
          var elementToAppendTo = document.querySelectorAll(a);
          for (i = 0; i < elementToAppendTo.length; i++) {
            var newInnerElement = document.createElement('div');
            // Create an element and append it
            newInnerElement.innerHTML = b;
            elementToAppendTo[i].appendChild(newInnerElement);
            // Remove the parent div
            var fragment = document.createDocumentFragment();
            while (newInnerElement.firstChild) {
              fragment.appendChild(newInnerElement.firstChild);
            }
            newInnerElement.parentNode.replaceChild(fragment, newInnerElement);
          }
        } else {
          throw 'appendHTML: DOM "' + a + '" has not been defined as an ID or class.';
        }
      }
    }
  } catch (err) {
    console.error(err);
    console.trace('^');
  }
}
